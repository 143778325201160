var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h5-detail-wrap" }, [
    _c("div", { staticClass: "form-cover", on: { click: _vm.test } }, [
      _c("img", { attrs: { src: _vm.detail.cover } }),
    ]),
    _c("div", { staticClass: "form-title" }, [_vm._v(_vm._s(_vm.detail.name))]),
    _c("div", { staticClass: "form-line-info" }, [
      _c("span", [_vm._v("创建时间：")]),
      _c("span", [_vm._v(_vm._s(_vm.detail.createTime))]),
    ]),
    _vm.type == "form"
      ? _c("div", { staticClass: "form-line-info" }, [
          _c("span", [_vm._v("开始时间：")]),
          _c("span", [
            _vm._v(
              _vm._s(_vm.detail.fillStartTime ? _vm.detail.fillStartTime : "——")
            ),
          ]),
        ])
      : _vm._e(),
    _vm.type == "form"
      ? _c("div", { staticClass: "form-line-info" }, [
          _c("span", [_vm._v("截止时间：")]),
          _c("span", [
            _vm._v(
              _vm._s(_vm.detail.fillEndTime ? _vm.detail.fillEndTime : "——")
            ),
          ]),
        ])
      : _vm._e(),
    _vm.type == "form"
      ? _c("div", { staticClass: "form-line-info" }, [
          _c("span", [_vm._v("填报人数：")]),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.detail.fillNum != null
                  ? _vm.detail.fillNum + "人可填"
                  : "填报人数无限制"
              )
            ),
          ]),
          _vm.detail.fillNumPublic ? _c("span", [_vm._v(",")]) : _vm._e(),
          _vm.detail.fillNumPublic
            ? _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.detail.completedFillNum != null
                      ? _vm.detail.completedFillNum + "人已填"
                      : ""
                  )
                ),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.type == "project"
      ? _c("div", { staticClass: "form-line-info" }, [
          _c("span", [_vm._v("类型：")]),
          _c("span", [_vm._v("应用")]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "form-line-info" }, [
      _c("span", [_vm._v("创建人：")]),
      _c("span", [
        _vm._v(
          _vm._s(
            _vm.detail.founder ? _vm.detail.founder : _vm.detail.createName
          )
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "form-fill-btn", on: { click: _vm.onAppletLiteClick } },
      [_vm._v("查看详情，请点击进入微信小程序")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }