<template>
  <div class="h5-detail-wrap">
    <div class="form-cover" @click="test">
      <img :src="detail.cover">
    </div>
    <div class="form-title">{{detail.name}}</div>
    <div class="form-line-info">
      <span>创建时间：</span>
      <span>{{detail.createTime}}</span>
    </div>
    <div class="form-line-info" v-if="type == 'form'">
      <span>开始时间：</span>
      <span>{{detail.fillStartTime ? detail.fillStartTime : '——'}}</span>
    </div>
    <div class="form-line-info" v-if="type == 'form'">
      <span>截止时间：</span>
      <span>{{detail.fillEndTime ? detail.fillEndTime : '——'}}</span>
    </div>
    <div class="form-line-info" v-if="type == 'form'">
      <span>填报人数：</span>
      <span>{{detail.fillNum != null ? detail.fillNum + '人可填' : '填报人数无限制'}}</span>
      <span v-if="detail.fillNumPublic">,</span>
      <span v-if="detail.fillNumPublic">{{detail.completedFillNum != null ? detail.completedFillNum + '人已填' : '' }}</span>
    </div>
    <div class="form-line-info" v-if="type == 'project'">
      <span>类型：</span>
      <span>应用</span>
    </div>
    <div class="form-line-info">
      <span>创建人：</span>
      <span>{{detail.founder ? detail.founder : detail.createName}}</span>
    </div>
    <div class="form-fill-btn" @click="onAppletLiteClick">查看详情，请点击进入微信小程序</div>
  </div>
</template>

<script>
import {shareImg, formDetail, projectDetail} from "@/api/system/form"
import {generateScheme} from "../../../api/tool/wechat";

export default {
  name: "h5Detail",
  components: {},
  data() {
    return {
      id: null,
      type: null,
      base64DataUrl: '',
      key: 0,
      detail: {},
      testShowUrl:''
    }
  },
  created() {

    let clientWidth = document.body.clientWidth
    let clientHeight = document.body.clientHeight
    this.width = clientWidth
    this.height = clientHeight

    this.id = this.decode(this.$route.params.id)

    if (!this.isBlank(this.$route.query.type)) {
      this.type = this.decode(this.$route.query.type)
    }

    // this.getShareImage(this.id, this.type)

    // console.log(this.type)

    if(this.type == 'form') {
      this.getFormDetail()
    } else {
      this.getProjectDetail()
    }

  },
  mounted() {

  },
  methods: {
    // 新版---生成分享图片
    getShareImage(id, type) {
      let _this = this
      shareImg(id, type).then((res)=>{
        if (res.code == 200) {
          _this.base64DataUrl = 'data:image/jpg;base64,' + res.data
        }
      })
    },
    // 2021/09/30 新版
    getFormDetail() {
      let _this = this
      formDetail(_this.id).then(response => {
        if (response.code == 200) {
          _this.detail = response.data
        }
      })
    },
    getProjectDetail() {
      let _this = this
      projectDetail(_this.id).then(response => {
        if (response.code == 200) {
          _this.detail = response.data
        }
      })
    },
    onAppletLiteClick() {
      let _this = this
      let param = {
        type: _this.type,
        id: _this.id + ''
      }
      generateScheme(param).then(response => {
        let schemeUrl = response.data
        window.location.href = schemeUrl
      })
    },
    test(){
       let _this = this
      let param = {
        type: _this.type,
        id: _this.id + ''
      }
      generateScheme(param).then(response => {
        let schemeUrl = response.data
        this.testShowUrl=schemeUrl;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .h5-detail-wrap {
    width: 100%;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    //justify-content: center;
    //align-items: center;
    .share-mobile-image{
      width: 100%;
      height: auto;
      image-rendering: -moz-crisp-edges; /* Firefox */
      image-rendering: -o-crisp-edges; /* Opera */
      image-rendering: -webkit-optimize-contrast; /*Webkit (non-standard naming) */
      image-rendering: crisp-edges;
      -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }
    .form-cover{
      width: 100%;
      height: 225px;
      vertical-align: middle;
      img {
        width: 100%;
        max-width: 100%;
        height: 225px;
      }
    }
    .form-title{
      font-size: 16px;
      color: #333333;
      line-height: 25px;
      width: 100%;
      padding: 7px 15px 5px;
      box-sizing: border-box;
    }
    .form-line-info{
      font-size: 14px;
      color: #7F7F7F;
      line-height: 25px;
      width: 100%;
      padding: 0 15px;
      box-sizing: border-box;
    }
    .form-fill-btn{
      width: calc(100% - 60px);
      margin: 20px 30px;
      height: 50px;
      line-height: 50px;
      border-radius: 6px;
      background: #10AEFF;
      font-size: 16px;
      color: #FFFFFF;
      text-align: center;
    }
  }
</style>
